<template lang="pug">
    .operations-debit
        .title(v-if="!hideWithdrawalMethods") {{ $t('profile.payments.paymentMethodLabel') }}
        .title(v-if="isCardsActive") {{ $t('profile.payments.withdrawalCardsLabel') }}

        .debit-methods(v-if="!hideWithdrawalMethods")
            .cards(v-if="cardsFlag !== '0'" @click="handleMethod('cards')"
                  :class="{ 'active': isCardsActive, 'disabled': true }")
              .up-head
                span {{ $t('profile.payments.withdrawalCardLabel') }}

              .pmethods
                .card-icons(v-for="(method, idx) in modifiedCards" :key="method.id"
                        :class="{ 'disabled': true, 'active': method.showMode }")
                    img.pmethod-image(:src="method.image" alt="No image")

            .companies(v-if="companiesFlag !== '0'" @click="handleMethod('companies')"
                      :class="{ 'active': isCompanyActive, 'disabled': true }")
              .up-head
                span {{ $t('profile.payments.withdrawalCompanyLabel') }}

              .pmethods.ew
                .ew(:class="{ 'disabled': false, 'active': false }" v-for="(method, idx) in d_e_wallets" :key="method.id")
                  img.ew-image(:src="method.image" alt="No image")

        .debit-payment(v-if="hideWithdrawalMethods")
          ValidationObserver(ref="withdrawalPayment" v-slot="{ passes }" tag="form")
            form.payment(:class="{ 'active': isGenerateActive }" @submit.prevent="passes(handleWithdrawal)")
              .body
                  .body-top
                    .ccard-first-wrapper(v-show="isCardsActive")
                      .ccard-img-wrapper
                        img.ccard-img(:src="require('@/assets/images/personal/payments/mini_card_icon.svg')" alt="cards")
                      TextInput(name="card" :renderedTextLabel="$t('profile.payments.cardNumberLabel')"
                                rules="required|luhn|length:19" @textInputEmit="listenTextInput"
                                :validDiv="false" :invalidDiv="false"
                                :hasVMask="true" :maskTemplate="'#### #### #### ####'" :floatingLabel="true"
                                :isOperations="true" :clearFields="clearFields")

                    //.ccard-second-wrapper(v-show="isCardsActive")
                    //  TextInput.exp-wrapper(name="exp" :renderedTextLabel="$t('profile.payments.expirationLabel')"
                    //            :key="updateKey+2" :floatingLabel="true"
                    //            rules="required|length:7"
                    //            @textInputEmit="listenTextInput" :validDiv="false" :invalidDiv="false"
                    //            :isOperations="true" :hasVMask="true" :maskTemplate="'## / ##'"
                    //            :clearFields="clearFields")
                    //
                    //  TextInput(name="cvc" :renderedTextLabel="$t('profile.payments.cvcLabel')"
                    //            rules="required|length:3" :key="updateKey+3" :floatingLabel="true"
                    //            @textInputEmit="listenTextInput" :validDiv="false" :invalidDiv="false"
                    //            :isOperations="true"
                    //            :hasVMask="true" :maskTemplate="'###'"
                    //            :isPssswordFont="true" :clearFields="clearFields")

                    .input-wrapper(:class="{ 'cc': isCardsActive }")
                        TextInput(name="summ" :renderedTextLabel="amountPlaceholder"
                                rules="required|minWithdrawal|maxWithdrawal" :floatingLabel="true"
                                @textInputEmit="listenTextInput" :validDiv="false" :invalidDiv="false"
                                :isOperations="true" refText="summInput" step="0.01" :clearFields="clearFields")

                  .body-bottom
                    p.fees(v-if="isCompanyActive && currentLanguage === 'en-EN'") {{ $t('payments.bwanaDepositTitle') }}
                    
                    p.agree {{ $t('profile.payments.agree1Label') }}
                    p.agree-link(@click="callPaymentPolicy()") {{ $t('footer.footerPaymentPolicyLabel') }}
                     p.forb {{ $t('profile.payments.agree3Label') }}

                    .deposit-btn-wrapper
                        button.deposit-btn(:class="{ 'disabled': false, 'clicked': isWithdrawalClicked }"
                                    type="submit") {{ $t('profile.payments.withdrawalButton') }}
</template>

<script>

const TextInput = () => import('@/components/serviceComponents/textInput/TextInputSW');

import Withdrawal from '../Withdrawal.vue';

export default {
  extends: Withdrawal,
  components: {
    TextInput,
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/personal/withdrawal_sw.scss";
</style>